<template>
  <b-row>
    <b-col cols="12">
      <b-card title="All Fundings">

        <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search"
                type="text"
              />
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="d-inline-flex mr-1"
                variant="outline-success"
                :to="{name: 'add-fundings'}"
              >
                <feather-icon
                  class="mr-50"
                  icon="PlusIcon"
                />
                <span class="align-middle text-nowrap">Add Funding</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="d-inline-flex mr-1"
                variant="outline-primary"
              >
                <feather-icon
                  class="mr-50"
                  icon="PlusIcon"
                />
                <span class="align-middle text-nowrap">Assign Team</span>
              </b-button>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <b-avatar
                :src="props.row.avatar"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge>
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  no-caret
                  toggle-class="text-decoration-none"
                  variant="link"
                  dropright
                  size="sm"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      class="text-body align-middle"
                      icon="MoreVerticalIcon"
                      size="16"
                    />
                  </template>
                  <b-dropdown-item>
                    <feather-icon
                      class="mr-50"
                      icon="CheckSquareIcon"
                    />
                    <span>Due Dilligence Manager</span>
                  </b-dropdown-item>
                  <b-dropdown-item :to="`/fundings/manager/${props.row.id}`">
                    <feather-icon
                      class="mr-50"
                      icon="DollarSignIcon"
                    />
                    <span>Funding Manager</span>
                  </b-dropdown-item>
                  <b-dropdown-item variant="danger">
                    <feather-icon
                      class="mr-50"
                      icon="TrashIcon"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-col>
    <!-- Ind/Limited Res. modal -->
    <b-modal
      id="unitResource"
      title="Basic Modal"
      ok-only
      hide-header-close
    >
      <b-card-text>
        <i>Total Units Available:</i> 12<br>
        <i>Totat Units Availed by Startups:</i> 3 <br>
        <span class="font-medium-2 text-primary">Grand Total: 15 <br></span>
      </b-card-text>
    </b-modal>
    <b-modal
      id="sharedResource"
      title="Basic Modal"
      ok-only
      hide-header-close
    >
      <b-card-text>
        Shared Resource Booking.
      </b-card-text>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
// import store from '@/store'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Startup',
          field: 'startup',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Due Dilligence',
          field: 'dueDilligence',
        },
        {
          label: 'Funds Disbursed/Asked',
          field: 'total_funds_disbursed',
        },
        {
          label: 'Funding Strucuture',
          field: 'fundingStrucuture',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterDropdownItems: ['Current', 'Professional', 'Rejected', 'Resigned', 'Applied'],
          },
        },
        {
          label: 'Equity Holding/ Debt Interest',
          field: 'equityDebt',
          formatFn(value) {
            return `${value}%`
          },
        },
        {
          label: 'Total Trench',
          field: 'totalTrench',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  apollo: {
    rows: {
      query: gql`
        {
        funding_basicinfo {
                funds_sanctioned
                id
                valuation
                total_funds_disbursed
            }
        }
      `,
      update: data => data.funding_basicinfo,
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
